import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import Organization from './components/organization.vue';
import customer from './components/customer.vue';
import productList from './components/productlist.vue';

formCreate.component('Organization', Organization);
formCreate.component('customer', customer);
formCreate.component('productList', productList);
export default {
  extends: Form,
  data() {
    return {
      formFunctionCode: 'addForm',
      optionsList: [],
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  async mounted() {
    await this.getDictSelect();
    await this.initEditValue();
  },
  methods: {
    formComplete() {
      this.hiddenFields(true, ['customerReqVoList']);
      this.hiddenFields(true, ['orgReqVoList']);
      this.hiddenFields(true, ['checkCycle']);
      this.hiddenFields(true, ['executeMode']);
      this.hiddenFields(true, ['executeDate']);
    },
    setRule(item) {
      const v = item;
      if (v.field === 'instructType') {
        v.on = {
          ...v.on,
          change: (e) => {
            if (e === 'org') {
              this.hiddenFields(true, ['customerReqVoList']);
              this.hiddenFields(false, ['orgReqVoList']);
              this.setValue({
                customerReqVoList: [],
              });
            } else if (e === 'customer') {
              this.hiddenFields(false, ['customerReqVoList']);
              this.hiddenFields(true, ['orgReqVoList']);
              this.setValue({
                orgReqVoList: [],
              });
            }
          },
        };
      }
      if (v.field === 'orgReqVoList') {
        v.props = {
          ...v.props,
          params: {
            functionCode: 'organization_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
          autosize: { minRows: 1, maxRows: 6 },
        };
        v.on = {
          ...v.on,
          change: (e) => {
            const arr = [...e];
            const orgVos = [];
            arr.forEach((m) => {
              orgVos.push({
                orgName: m.orgName,
                orgCode: m.orgCode,
              });
            });
            this.setValue({
              orgReqVoList: orgVos || [],
            });
          },
        };
      }
      if (v.field === 'customerReqVoList') {
        v.props = {
          ...v.props,
          params: {
            functionCode: 'customer_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
          autosize: { minRows: 1, maxRows: 6 },
        };
        v.on = {
          ...v.on,
          change: (e) => {
            const arr = [...e];
            const customerArry = [];
            arr.forEach((m) => {
              customerArry.push({
                customerName: m.customerName,
                customerCode: m.customerCode,
              });
            });
            this.setValue({
              customerReqVoList: customerArry || [],
            });
          },
        };
      }
      if (v.field === 'productName') {
        v.props = {
          ...v.props,
          params: {
            functionCode: 'product_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
          autosize: { minRows: 1, maxRows: 6 },
        };
        v.on = {
          ...v.on,
          change: (e) => {
            this.setValue({
              productName: e || [],
            });
          },
        };
      }
      if (v.field === 'checkMode') {
        v.on = {
          ...v.on,
          change: (e) => {
            if (e === '一次性盘点') {
              this.hiddenFields(true, ['checkCycle']);
              this.hiddenFields(true, ['executeMode']);
              this.hiddenFields(false, ['executeDate']);
            } else if (e === '周期性盘点') {
              this.hiddenFields(false, ['executeMode']);
              this.hiddenFields(true, ['executeDate']);
              this.hiddenFields(false, ['checkCycle']);
            }
            this.setValue({
              checkCycle: '',
              executeMode: '',
              executeDate: [],
            });
          },
        };
      }
      if (v.field === 'executeDate') {
        v.props = {
          ...v.props,
          type: 'daterange',
          pickerOptions: this.pickerOptions,
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          unlinkPanels: true,
          align: 'right',
          valueFormat: 'yyyy-MM-dd',
        };
      }
      if (v.field === 'checkCycle') {
        v.on = {
          ...v.on,
          change: (e) => {
            this.getOptions(e);
            this.setValue({
              checkCycle: e,
              executeMode: null,
            });
          },
        };
      }
      if (v.field === 'executeMode') {
        v.on = {
          ...v.on,
          change: (e) => {
            this.setValue({
              executeMode: e,
            });
          },
        };
      }
      if (v.field === 'describe') {
        v.on = {
          ...v.on,
          change: (e) => {
            this.setValue({
              describe: e,
            });
          },
        };
      }
      return v;
    },
    async initEditValue() {
      if (this.formConfig.code === 'edit') {
        await request
          .post('/sfa/sfaStockCheck/getStockCheck', this.formConfig.row.id)
          .then(async (res) => {
            await this.getOptions(res.result.checkCycle);
            if (res.result.instructType === 'org') {
              this.hiddenFields(true, ['customerReqVoList']);
              this.hiddenFields(false, ['orgReqVoList']);
              this.setValue({
                orgReqVoList: res.result.orgRespVoList,
                customerReqVoList: [],
              });
            } else if (res.result.instructType === 'customer') {
              this.hiddenFields(false, ['customerReqVoList']);
              this.hiddenFields(true, ['orgReqVoList']);
              this.setValue({
                orgReqVoList: [],
              });
            }
            if (res.result.checkMode === 'once') {
              this.hiddenFields(true, ['checkCycle']);
              this.hiddenFields(true, ['executeMode']);
              this.hiddenFields(false, ['executeDate']);
              this.setValue({
                checkCycle: '',
                executeMode: '',
              });
            } else if (res.result.checkMode === 'cycle') {
              this.hiddenFields(false, ['executeMode']);
              this.hiddenFields(true, ['executeDate']);
              this.hiddenFields(false, ['checkCycle']);
              this.setValue({
                executeDate: [],
              });
            }
            setTimeout(() => {
              this.setValue({
                ...res.result,
                executeDate: [res.result.executeStartDate, res.result.executeEndDate],
                productName: [{
                  productCode: res.result.productCode,
                  productLevelCode: res.result.productLevelCode,
                  productLevelName: res.result.productLevelName,
                  productName: res.result.productName,
                },
                ],
                executeMode: String(res.result.executeMode),
              });
            }, 500);
          });
      }
    },
    submit() {
      const formData = this.getFormData();
      if (!formData) {
        return false;
      }
      if (formData.instructType === 'org' && !formData.orgReqVoList.length) {
        this.$message.error('请选择组织');
        return;
      }
      if (formData.instructType === 'customer' && !formData.customerReqVoList.length) {
        this.$message.error('请选择客户');
        return;
      }
      if (!formData.checkMode) {
        this.$message.error('请选择盘点模式');
        return;
      }
      if (formData.checkMode === 'once' && !formData.executeDate) {
        this.$message.error('请选择执行时间');
        return;
      }
      if (formData.checkMode === 'cycle') {
        if (!formData.checkCycle) {
          this.$message.error('请选择盘点周期');
          return;
        }
        if (!formData.executeMode) {
          this.$message.error('请选择执行时间');
          return;
        }
      }
      const params = {
        ...formData,
        productCode: formData.productName[0].productCode,
        productLevelCode: formData.productName[0].productCode,
        productLevelName: formData.productName[0].productLevelName,
        productName: formData.productName[0].productName,
        unit: formData.productName[0].unit,
        executeStartDate: formData.executeDate ? formData.executeDate[0] : '',
        executeEndDate: formData.executeDate ? formData.executeDate[1] : '',
      };
      delete params.title1;
      delete params.title2;
      delete params.title3;
      delete params.title4;
      delete params.title5;
      let url = '/sfa/sfaStockCheck/saveCheck';
      if (this.formConfig.code === 'edit') {
        url = '/sfa/sfaStockCheck/editCheck';
        params.id = this.formConfig.row.id;
        params.instructCode = this.formConfig.row.instructCode;
      }

      request.post(url, params).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
    getOptions(e) {
      const executeDate1 = this.getRule('executeMode');
      if (e === 'month') {
        executeDate1.options = this.optionsList.filter((it) => it.value === '0');
      } else if (e === 'season') {
        executeDate1.options = this.optionsList.slice(0, 4);
      } else if (e === 'half_year') {
        executeDate1.options = this.optionsList.slice(0, 7);
      } else if (e === 'year') {
        executeDate1.options = this.optionsList.slice(0, 13);
      } else {
        executeDate1.options = this.optionsList;
      }
    },
    // 获取数据字典
    async getDictSelect() {
      const params = [
        'sfa_stock_check_execute',
      ];
      await request.post('/mdm/mdmdictdata/batchDictSelect', params).then((res) => {
        if (res.success) {
          res.result.sfa_stock_check_execute.map((v) => {
            this.optionsList.push({
              ...v,
              value: v.dictCode,
              label: v.dictValue,
            });
            return v;
          });
        }
      });
    },
  },
};
